.MuiInputLight input{
    color: #fff !important;
}

.MuiSvgIconLight{
    color: #fff !important;
}

.MuiInputLight ::after{
    border-bottom: 1px solid #fff !important;
}

.MuiInputLabelLight{
    color: #fff !important;
}